<template>
  <div class="container-fluid">
    <CCol>
      <CCard>
        <CForm @submit.prevent="handleSubmit">
          <CCardHeader>
            <strong> Add User </strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="6">
                <label class="labels">First Name</label
                ><input
                  type="text"
                  v-model="vmodel.firstName"
                  class="form-control"
                  placeholder="Enter Your First Name"
                  :class="{
                    'is-invalid': submitted && $v.vmodel.firstName.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.vmodel.firstName.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.vmodel.firstName.required"
                    >First Name is required</span
                  >
                </div>
              </CCol>
              <CCol sm="6">
                <label class="labels">Last Name</label
                ><input
                  type="text"
                  v-model="vmodel.lastName"
                  class="form-control"
                  placeholder="Enter Your Last Name"
                  :class="{
                    'is-invalid': submitted && $v.vmodel.lastName.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.vmodel.lastName.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.vmodel.lastName.required"
                    >Last Name is required</span
                  >
                </div>
              </CCol>
              <CCol sm="6">
                <label class="labels">Email</label
                ><input
                  type="text"
                  v-model="vmodel.email"
                  class="form-control"
                  placeholder="Enter Your Email"
                  :class="{
                    'is-invalid':
                      (submitted && $v.vmodel.email.$error) ||
                      (modelstate_email != null && modelstate_email != ''),
                  }"
                />
                <div
                  v-if="
                    (submitted && $v.vmodel.email.$error) ||
                    (modelstate_email != null && modelstate_email != '')
                  "
                  class="invalid-feedback"
                >
                  <span v-if="!$v.vmodel.email.required"
                    >Email is required</span
                  >
                  <span v-if="!$v.vmodel.email.email">Email is invalid</span>
                  <!-- <span v-if="!$v.vmodel.email.isValidDoamin"
                    >Below Email Domains Are Allowed.
                    <ul>
                      <li>marvelconnect.com</li>
                      <li>link.cuhk.edu.hk</li>
                      <li>cuhk.edu.hk</li>
                    </ul>
                  </span> -->
                  <span
                    v-if="modelstate_email != null && modelstate_email != ''"
                    >{{ modelstate_email }}</span
                  >
                </div>
              </CCol>

              <!-- <CCol sm="6" v-if="id == null"> -->
              <CCol sm="6">
                <label class="labels">Password</label>

                <CRow>
                  <CCol>
                    <input
                      type="password"
                      v-model="vmodel.password"
                      class="form-control"
                      placeholder="Enter Your Password"
                    
                      :class="{
                        'is-invalid': submitted && $v.vmodel.password.$error,
                      }"
                    />
                     <div
                  v-if="submitted && $v.vmodel.password.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.vmodel.password.required"
                    >Password is required</span
                  >
                </div>
                  </CCol>
                  <!-- <CCol md="3" v-if="id != null">
                    <CButton
                      class="mr-1"
                      @click="handleChangePassword"
                      color="primary"
                      >Change</CButton
                    ></CCol> -->
                 
                </CRow>

               
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" class="mr-1" color="primary">Save</CButton>
            <CButton
              type="button"
              router-link
              :to="{ name: 'User List' }"
              class="mr-1"
              color="secondary"
              >Cancel</CButton
            >
          </CCardFooter>
        </CForm>
      </CCard>
    </CCol>
  </div>
</template>

<script>
import APIService from "@/services/api.service.js";
import { required, requiredIf, email } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      id: this.$route.params.id,
      vmodel: {
        userType: "user",
      },
      modelstate_email: "",
      submitted: false,
    };
  },
  validations() {
    return {
      vmodel: {
        firstName: { required },
        lastName: { required },
        email: {
          required,
          email,
          // isValidDoamin: (value) =>
          //   ["marvelconnect.com", "link.cuhk.edu.hk", "cuhk.edu.hk"].some(
          //     (emailDomain) => value.indexOf(emailDomain) !== -1
          //   ),
        },
        password: {
          requiredIf: requiredIf(() => {
            return this.id == null;
          }),
        },
      },
    };
  },
  created() {
    if (this.id != undefined && this.id != null) {
      document.title = this.$store.state.projecttitle + " - Edit User";
      APIService.getById("/users/getuser", this.id).then((response) => {
        this.$set(this, "vmodel", response.data);
      });
    } else {
      document.title = this.$store.state.projecttitle + " - Add User";
    }
  },
  methods: {
    handleSubmit() {
      console.log("1");
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.vmodel.usertype = "Users";
      APIService.post(
        "/users/checkuserexist",
        this.vmodel,
        this.id == null ? "000000000000000000000000" : this.id
      ).then((response) => {
        if (response && response.data) {
          if (
            this.vmodel.email.toLowerCase() == response.data.email.toLowerCase()
          ) {
            this.modelstate_email = "Email Already Exist";
          }
        } else {
          if (this.id != undefined && this.id != null) {
            APIService.post("/users/updateuser", this.vmodel, this.id).then(
              () => {
                this.$successAlert("User Updated successfully.");
                this.$router.push({ name: "User List" });
                this.submitted = false;
              }
            );
          } else {
            APIService.post("/users/adduser", this.vmodel).then(() => {
              this.$successAlert("User added successfully.");
              this.$router.push({ name: "User List" });
              this.submitted = false;
            });
          }
        }
      });
    },
    // handleChangePassword: function(){
    //    this.$v.$touch();
    
    // 	  APIService.post("/users/updateuserpassword", this.vmodel, this.id).then(
    //           () => {
    //             this.$successAlert("Change Password successfully.");
    //              this.$router.push({ name: "User List" });
    //             this.submitted = false;
    //           }
    //         );
    // },
  },
};
</script>
<style scoped>
.labels {
  margin-top: 10px !important;
}
</style>